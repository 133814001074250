require('./includes/photoswipe');

require([
    'smooth-scroll/dist/smooth-scroll.polyfills.min'
], function (SmoothScroll) {
    let scroll = new SmoothScroll('a[href*="#"]:not([class=page-link])', {
        speed: 500,
        speedAsDuration: true,
        header: '#header-wrapper',
        updateURL: false,
        popstate: false,
        topOnEmptyHash: true
    });
});

//HEADROOM
require([
    'headroom.js/dist/headroom'
], function (Headroom) {
// grab an element
    var myElement = document.getElementById("header-wrapper");
// construct an instance of Headroom, passing the element
    var headroom = new Headroom(myElement);
// initialise
    headroom.init();
});

//Cookie bar accept
if(document.getElementById("js-acceptCookies")) {
    document.getElementById("js-acceptCookies").addEventListener("click", function (e) {
        e.preventDefault();
        document.cookie = 'cookieConsent' + "=" + 1 + "; expires=" + (60 * 60 * 24 * 365 * 3) + "; path=/";
        document.getElementById("cookies-bar").classList.add('active');
    });
}


// Listen for smooth-scroll and hide Burger Menu after finish scroll to element
document.addEventListener('scrollStart', function (event) {
    checkOpenBurger();
});

//BURGER MENU
const header = document.getElementById('header-wrapper');
const burgerMenu = document.getElementById('burger-menu');
const burgerMenuOverlay = document.getElementById('burger-menu-overlay');
const burgerMenuClose = document.getElementById("burger-menu-close");

function checkOpenBurger() {
    if (header.classList.contains("open")) {
        header.classList.remove('open');
        burgerMenu.classList.remove('open');
        burgerMenuOverlay.classList.remove('open');
    }
}

document.getElementById("nav-icon").addEventListener("click", function () {
    if (header.classList.contains("open")) {
        header.classList.remove('open');
        burgerMenu.classList.remove('open');
        burgerMenuOverlay.classList.remove('open');
    } else {
        header.classList.add('open');
        burgerMenu.classList.add('open');
        burgerMenuOverlay.classList.add('open');
    }
});

burgerMenuOverlay.addEventListener("click", function () {
    checkOpenBurger();
});

if(burgerMenuClose) {
    burgerMenuClose.addEventListener("click", function () {
        checkOpenBurger();
    });
}

document.querySelectorAll('.scrollToFullpage').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        fullpage_api.moveTo(anchor.getAttribute('data-item'));
        checkOpenBurger();
    });
});
